@media only screen  and (min-width: 1200px) {
    .container {
        max-width: 1380px !important;
    }
}
@media only screen  and (max-width: 767px) {
    .defalut-button{
        border-radius: 4px;
    }
    .mobileheader{
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 63px;
        padding: 0 20px;
        background: #000000;
        border: 1px solid #1D1D1D;
        box-sizing: border-box;
    }
    .mobile-right-section{
        display: flex;
        align-items: center;
    }
    .mobile-browse{
        padding-right: 20px;
    }
    .mobile-browse span{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color:#ffffff;
    }
    .mobile-list-nav{
        margin:20px 0 0 0;
        padding:0;
    }
    .mobile-list-nav li{
        margin:0;
        padding: 0 0 15px 0;
    }
    .mobile-list-nav li a{
        font-family: 'sofiapro';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: rgba(255, 255, 255, 0.5);
        text-decoration: none;
    }
    .mobile-list-nav li a:hover{
        color:#ffffff;
    }
    .header-links-dropdown-content{
        right:61px;
    }
    .mobile-nav-show{
        background: #181818;
        transition: .4s ease-in-out;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
        width: 320px;
        position: absolute;
        right: 0;
        padding: 20px;
        z-index: 9;
    }
    .login-for-mobile{
        background: #181818;
        transition: .4s ease-in-out;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
        width: 120px;
        position: absolute;
        right: 0;
        padding: 20px;
    }
    .login-for-mobile span{
        font-family: 'sofiapro';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .Login-modals.modal .modal-content form {
        width: 96%;
        margin: 0 auto;
    }
    .bannersection-mobile{
        width:100%;
        float:left;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 30px 0 40px 0;
        border-bottom: 1px solid #383838;
    }
    .Middle-content{
        width:100%;
    }
    .bannersection-mobile .Middle-content h2{
        font-family: 'sofiapro';
        font-weight: 800;
        font-size: 40px;
        line-height: 126.1%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #FFFFFF;
    }
    .bannersection-mobile .right-video{
        width:100%;
    }
    .right-video .courses-slider img{
        width:100%;
    }
    .hide-for-desktop{
        display: block;
    }
    .hide-for-desktop .MuiPaper-root {
        color: #FFFFFF;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #171717;
    }
    .hide-for-desktop .MuiPaper-root  .WithStyles {
        min-height: 56px;
        border-bottom: 1px solid rgba(0, 0, 0, .125);
        margin-bottom: 0px;
        background-color: #171717;
    }
    .hide-for-desktop .MuiAccordionSummary-root{
        padding: 0px;
        border-bottom: 1px solid #ffffff26;
        margin-bottom: 0;
    }
    .footer ul li a{
        color: #91929A;
        font-family: 'sofiapro';
    }
    .footer ul li {
        line-height: 1;
        padding-bottom: 20px;
    }
    .mobile-footer-list {
        margin-top: 10px;
    }
    .hide-for-desktop .MuiAccordionSummary-content{
        justify-content: space-between;
    }
    .hide-for-desktop  .MuiAccordionDetails-root{
        padding: 15px 0px;
    }
    .hide-for-desktop .MuiPaper-root .MuiTypography-body1{
        font-size: 18px;
        line-height: 24px;
        color:#ffffff;
        font-family: 'sofiapro';
        font-weight: normal;
    }
    .footer{
        background-color: #171717 !important;
        padding: 30px 0;
    }
    .footer .hide-for-mobile {
        display: none;
    }
    .mobile-newsletter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }
    .mobile-social-media{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .mobile-social-media ul{
        display: flex;
        align-items:center;
    }
    .mobile-social-media ul li{
        padding: 0px 30px;
    }
    .copy-riht-text{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .copy-riht-text p{
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #777777;
        font-family: 'sofiapro';
        margin-bottom: 0px;
    }
    .boxRight .col-md-6{
        padding: 0 !important;
    }
    .boxLeft .col-md-6{
        padding: 0 !important;
    }
    .profile-details {
        flex-direction: column;
    }
    .update-profile{
        justify-content: center;
    }
    .fileupload{
        flex-direction: column;
    }
    .upload-buttons {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-left: 0;
        margin-top: 25px;
    }
    .upload-buttons .upload-buton{
        margin-bottom:0;
        margin-right:15px;
    }
   .pass-update{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .payment-table {
        width: 100%;
        float: left;
        margin-left: -19px;
    }
    .userinfo-show{
        display: flex;
        align-items: center;
    }
    .userinfo-show span{
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        padding-left: 8px;
        font-weight: 500;
    }
    header.Header-main{
        display: none;
    }
    .defalut-heading span{
        height:2px;
    }
    .bannerSection{
        display: none;
    }
    .full-video-section{
        display: none;
    }
    .readytogo-section{
        display: none;
    }
    .our-stats-section{
        display: none;
    }
    .vertical-tab-section{
        display: none;
    }
    .tutor-list {
        width: 100%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tutor-list ul li{
        width: 27%
    }
    .tutor-list ul{
        text-align: center;
    }
    .tutor-list ul li img {
        width: 100%;
    }
    .tutor-list .tutor-info span{
        display: none;
    }
    .tutor-list .showAfterHover{
        display: none;
    }
    .tutor-list .tutor-info{
        width:100%;
        padding: 60px 5px 10px 5px;
    }
    .tutor-list .tutor-info p{
        font-size: 13px;
        font-weight: 500;
    }
    .home-testimonial-section{
        display: none;
    }
    .mobile-testimonial-section{
        width:100%;
        float:left;
        padding: 70px 0;
        display: block;
    }
    .mobile-testimonial-section h3{
        text-align: center;
    }
    .home-cat-list ul {
        width: 100%;
        padding: 0 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .home-cat-list ul li {
        margin: 10px 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 45%;
        border: 1px solid #383838;
        border-radius: 5px;
    }
    .home-cat-list ul li a {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 136.7%;
        text-align: center;
        color: #ffffff;
        transition: transform .2s;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .all-cat-mobile{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom: 20px;
    }
    .package-boxes {
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 40px 0;
        overflow-x: auto;
    }
    .feature-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        height: 480px;
    }
    .feature-list ul{
        padding: 0px 8px;
    }
    .package-boxes .package-col{
        min-width:300px;
    }
    .price-detail {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
    .user-profiles .tabs {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .user-profiles .tabs ul.nav.nav-tabs {
        max-width: 100%;
        border: none;
        margin-top: 0px;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: unset;
    }
    .user-profiles .tabs ul.nav.nav-tabs li {
        width: auto;
        padding-bottom: 0px;
    }
    .user-profiles .tabs ul.nav.nav-tabs li a.nav-link.active {
        color: #ffffff;
        background: transparent;
        border-bottom: 1px solid #FF4D4D !important;
        border:none;
    }
    .user-profiles .tabs ul.nav.nav-tabs li a {
        background: transparent;
        border: none;
        color: #ffffff;
        border-radius: 0px;
        width: 180px;
        font-size: 14px;
        margin-bottom: 0;
    }
    .user-profiles .tab-content{
        width:100%;
        padding: 20px;
        border:none;
    }
    .Profile-contents .simple-heading{
        display: none;
    }
    .course-detail-info {
        width: 100%;
        display: block;
    }
    .course-detail-page .container{
        padding: 0;
    }
    .course-detail-info .left-section {
        width: 100%;
        padding-right: 0;
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
    }
    .course-detail-info .right-section {
        width: 100%;
    }
    .title-info-desktop{
        display: none;
    }
    .title-info-mobile{
        display: block;
        padding: 20px 24px;
    }
    .detail-bottom-section {
        display: none;
    }
    .buy-buttons-mobile {
        display: block;
        padding: 0px 24px;
    }
    .buy-buttons-mobile .buy-buttons{
        display: block;
    }
    .buy-buttons-mobile .buttn-box{
        margin-bottom: 25px;
    }
    .buy-buttons-mobile .buttn-box span{
        margin-bottom: 5px;
    }
    .buy-buttons-mobile .defalut-button{
        width:100%;
        border-radius: 0px;
    }
    .buy-buttons-mobile .buttn-box a{
        width:100%;
        border-radius: 0px;
    }
    .buy-buttons{
        display: none;
    }
    .detail-actions{
        display: none;
    }
    .detail-actions.for-mobile-only {
        display: flex;
        padding: 0 24px;
    }
    .detail-actions.for-mobile-only a{
        width:110px;
        height:39px;
    }
    .detail-actions.for-mobile-only a span{
        font-size:16px;
    }
    .detail-actions.for-mobile-only a img {
        padding-left: 10px;
        display: none;
    }
    .bottom-section{
        border-top:none;
        padding: 0 24px;
        margin-top:30px;
    }
    .course-outline{
        width:100%;
    }
    .course-outline {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-left: none;
        padding: 40px 0px 0 0px;
    }
    .course-details-tabs-mobile{
        display: block;
    }
    .course-details-tabs-mobile ul.nav-tabs{
        border-bottom: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .course-details-tabs-mobile ul.nav-tabs  .nav-link{
        font-weight: normal;
        font-size: 20px;
        line-height: 176.7%;
        color: rgba(255, 255, 255, 0.5);
        border-bottom: 2px solid transparent !important;
        border:none;
    }
    .course-details-tabs-mobile ul.nav-tabs a.nav-link.active{
        background: transparent;
        color:#ffffff;
        border-bottom: 2px solid #FF4D4D !important;
    }
    .outline-text {
        margin-top: 10px;
    }
    .course-details-tabs-mobile .download-resourses{
        justify-content: center;
        align-items: center;
    }
    .download-resourses a{
        opacity: 1;
    }
    .download-resourses a span{
        opacity: 1;
    }
    .course-details-tabs-mobile .course-list-box{
        display: block;
    }
    .course-list-box{
        display: none;
    }
    .breadcrumbs-section{
        display: none;
    }
    .courses-cat-sub ul.nav.nav-tabs::-webkit-scrollbar {
        width: 4px;
        
    }
    .courses-cat-sub ul.nav.nav-tabs::-webkit-scrollbar-thumb {
        background-color: #000000;
        outline: 1px solid #000000;
        border-radius:70px;
    }
    .courses-cat-sub ul.nav.nav-tabs{
        border-bottom: none;
        padding-bottom: 0px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        overflow-x: auto;
        float: left;
        flex-direction: row;
        flex-wrap: unset;
    }
    .courses-cat-sub ul.nav.nav-tabs li.nav-item a.nav-link{
        width: 200px;
        margin: 0;
        padding: 0px;
        text-align: center;
        font-size: 16px;
        line-height: 28px;
        border-bottom: 1px solid transparent;
    }
    .courses-cat-sub ul.nav.nav-tabs li.nav-item a.nav-link.active{
        border-bottom: 1px solid #FF4D4D;
    }
    .courses-cat-sub .course-list{
        width:90%;
    }
    .register-box{
        display: block;
    }
    .register-left {
        display: none;
    }
    .register-right{
        width:100%;
        padding:30px 24px ;
    }
    .signheading-box{
        display: block;
    }
    .registration-arrow{
        margin-bottom: 15px;
        width:100%;
    }
    .alreadysign-logintext {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .register-right form .col-md-6{
        padding: 0px;
    }
    .register-right form .col-md-6.mb-0, .my-0{
        margin-bottom: 1rem;
    }
    .register-right form .phone-number {
        margin-bottom: 1rem;
    }
    .register-right form .buttons-box{
        display: block;
    }
    .register-right form .button-col{
        margin:25px 0 0 0;
    }
    .foudation-desktop{
        display: none;
    }
    .foudation-years-mobile{
        display: block;
        width:100%;
        float:left;
        margin-top:20px;
    }
    .foudation-years-mobile ul.nav.nav-tabs {
        border-bottom: none;
        padding-bottom: 0px;
        padding-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        overflow-x: auto;
        float: left;
        flex-direction: row;
        flex-wrap: unset;
    }
    .foudation-years-mobile  ul.nav.nav-tabs li.nav-item a.nav-link {
        border: none;
        border-bottom: 1px solid transparent;
        background: transparent;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.5);
        margin: 0;
        padding: 0px;
        text-align: center;
        width:145px;
    }

    .foudation-years-mobile ul.nav.nav-tabs li.nav-item a.nav-link.active {
        color: #ffffff;
        border-bottom: 1px solid #FF4D4D;
    }
    .foudation-years-mobile .learning-resources{
        padding: 0px 18px;
    }
    .foudation-years-mobile .learning-slider{
        margin-top:10px;
    }
    .foudation-years-mobile .learning-heading {
        justify-content: center;
        margin-top:30px;
    }
    .foudation-years-mobile .learning-heading a{
        font-size: 16px;
    }
    .foudation-years-mobile .learning-slider .courses-slider.new img{
        width:100%;
    }
    .defalut-heading h3{
        font-size: 22px;
    }
    .resources-list{
        width:100%;
    }
    .cate-for-mobile{
        display: block;
        margin:20px 0;
    }
    .cate-for-mobile .category-right-section{
        display: block;
    }
    .category-right-section{
        display: none;
    }
    .category-Sections{
        display: block;
        padding: 0 24px;
    }
    .category-left-section{
        width:100%;
        padding-right: 0px;
    }
    .pdp-boxes-list .col-md-4{
        border-right: none;
    }
    .pdp-boxes-list .col-md-4.borderbottom {
        border-bottom: 1px solid #E0E0E0;
    }
    .pdp-boxes {
        padding: 40px 20px 50px 20px;
    }
    .pdp-boxes a{
        font-size: 16px;
    }
    .personal-development-planing{
        display: block;
    }
    .personalinfo-box div span{
        width: auto;
        font-size: 16px;
    }
    .personalinfo-box div p{
        font-size: 16px;
    }
    .personalinfo-box div{
        justify-content: space-between;
    }
    .personalinfo-box{
        margin-bottom: 30px;
    }
    .cycle-descrption{
        margin-top:20px;
    }
    .practice-form {
        width: 100%;
    }
    .practice-items {
        width: 100%;
        display: block;
        margin-bottom:40px;
    }
    .practice-meta {
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
    }
    .personal-button-group a.practice-back{
        width:140px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
    }
    .personal-button-group a.next-plan{
        width:140px;
        margin-left:17px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
    }
    .reflection-col{
        justify-content: space-between;
    }
    .reflection-col p{
        font-size: 16px;
    }
    .reflection-col span{
        width:auto;
        font-size: 16px;
    }
    .choose-courses-tabs{
        padding: 40px 0px 20px 0px;
    }
    .personal-button-group{
        margin-top:20px;
    }
    .reflection-info{
        display: block;
    }
    .reflection-left {
        width: 100%;
    }
    .reflection-left-form{
        width:100%;
    }
    .reflection-area{
        width:100%;
        display: block;
    }
    .reflection-right-form{
        width:100%;
    }
    .reflection-right-form{
        width:100%;
    }
    .reflection-area textarea{
        width:100%;
    }
    .reflection-bottom-form{
        width:100%;
    }
    .annual-box{
        display: block;
        margin-bottom: 40px;
    }
    .annual-left{
        width:100%;
        justify-content: space-between;
    }
    .annual-left span{
        width:auto;
        font-size: 16px;
    }
    .annual-left p{
        font-size: 16px;
    }
    .annual-right{
        width:100%;
        margin-top: 10px;
        justify-content: space-between;
    }
    .annual-gdc-download a{
        width:320px;
    }
    .gdc-year .css-1iyne87-control{
        height:40px;
    }
    .gdc-head a{
        font-size: 16px;
        width:120px;
        font-weight: 500;
        letter-spacing: 0px;
    }
    .annual-right span{
        font-size: 14px;
    }
    .annual-gdc-download a span{
        letter-spacing: 0;
        font-size: 14px;
    }
    .gdc-note-line{
        display: block;
        margin-top:25px;
    }
    .choose-courses-tabs ul.nav.nav-tabs{
        border-bottom: none;
        padding-bottom: 0px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        overflow-x: auto;
        float: left;
        flex-direction: row;
        flex-wrap: unset;
    }
    .choose-courses-tabs ul.nav.nav-tabs .nav-item a.active{
        border-bottom: 1px solid #FF4D4D;
    }
    .choose-courses-tabs ul.nav.nav-tabs a.nav-link{
        width: 92px;
        margin: 0;
        padding: 0px;
        text-align: center;
        font-size: 16px;
        line-height: 28px;
        border-bottom: 1px solid transparent;
    }
    .choose-courses-tabs ul.nav.nav-tabs::-webkit-scrollbar {
        width: 4px;
        
    }
    .choose-courses-tabs  ul.nav.nav-tabs::-webkit-scrollbar-thumb {
        background-color: #000000;
        outline: 1px solid #000000;
        border-radius:70px;
    }
    .foudation-years-mobile ul.nav.nav-tabs::-webkit-scrollbar {
        width: 4px;
    }
    .foudation-years-mobile   ul.nav.nav-tabs::-webkit-scrollbar-thumb {
        background-color: #000000;
        outline: 1px solid #000000;
        border-radius:70px;
    }
    .user-profiles .tabs ul.nav.nav-tabs::-webkit-scrollbar{
        width: 4px;
    } 
    .user-profiles .tabs ul.nav.nav-tabs::-webkit-scrollbar-thumb {
        background-color: #000000;
        outline: 1px solid #000000;
        border-radius:70px;
    } 
    .previous-web-table{
        display: none;
    }
    .previous-mobile{
        display: block;
        width: 100%;
        float: left;
        padding: 20px 0;
    }
    .pdp-mobile-view {
        margin-bottom: 20px;
    }
    .tabs-mobile-head{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 4px 4px 0px 0px;
        height:42px;
        padding: 0 15px;
    }
    .tabs-mobile-head span{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #000000;
    }
    .tabs-mobile-content{
        width:100%;
        background: #000000;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 0;
        border-top:none;
    }
    .mobile-content-col {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);;
        padding: 15px 15px;
    }
    .mobile-content-col span{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 136.7%;
        color: #FF4D4D;
        width:50%;
    }
    .mobile-content-col p{
        margin:0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 136.7%;
        color: #FFFFFF;
        width:50%;
        text-align: right;
    }
    .mobile-content-col a{
        width:115px;
        height:36px;
        background: #FF4C4C;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.06em;
        color: #FFFFFF;
    }
    .mobile-content-col.bordernone{
        border-bottom: none;
    }
    .events-box{
        width:100%;
    }
    .events-divider{
        display: block;
    }
    .events-image-col{
        width:100%;
    }
    .Events-meta{
        width: 100%;
        padding: 20px 0;
    }
    .buy-buttons-mobile .watch-course-button{
        display: flex !important;
    }
    .watch-course-button{
        display: none !important;
    }
    .course-outline{
        border-left:none;
    }
    .dental-news{
        padding: 30px 20px;
    }
    .dental-news-list{
        display: block;
    }
    .news-image-box{
        width: 100%;
    }
    .news-content-box{
        width: 100%;
        padding: 20px 0;
    }
    .paper-filter{
        display: block;
    }
    .boxes-col{
        display: block;
    }
    .boxes-col .video-box{
        width:100%;
    }
    .right-review{
        width:100%;
        padding: 20px 0;
    }
    .courseslider-boxes .owl-dots{
        display: none;
    }
    .get-started-mentorship img{
        margin:15px 0;
    }
    .buton-for-mentor{
        margin-bottom: 25px;
        margin-top:0;
    }
    .become-a-mentor {
        width: 100%;
        background: #ffffff;
        height: 100%; 
        display: flex;
        align-items: center;
        justify-content: center;
         position: relative; 
        flex-direction: column;
        padding: 10px;
        float: left;
        text-align: center;
    }
    .become-mentor-buton {
        position: relative;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FF4D4D;
        height: 50px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff !important;
        width: 205px;
        margin: 10px auto 0 auto;
    }
    .course-list.cat-course .col-md-2{
        margin-bottom: 20px;
    }
    .Login-modals.schedule.modal .modal-dialog {
        max-width: 100%;
    }
    .slot-list .slot-col{
        width: 28.3%;
        font-size: 16px;
    }
}
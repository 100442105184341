body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'sofiapro';
  src: local('sofiapro'), url('./fonts/sofia-pro/sofiapro-light.otf') format("opentype");;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'sofiapro';
  src:local('sofiapro'), url('./fonts/sofia-pro/sofiapro-regular.otf') format("opentype");;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'sofiapro';
  src: local('sofiapro'), url('./fonts/sofia-pro/sofiapro-medium.otf') format("opentype");;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'sofiapro';
  src: local('sofiapro'), url('./fonts/sofia-pro/sofiapro-semibold.otf') format("opentype");;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'sofiapro';
  src: local('sofiapro'), url('./fonts/sofia-pro/sofiapro-bold.otf') format("opentype");;
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'sofiapro';
  src: local('sofiapro'), url('./fonts/sofia-pro/sofiapro-black.otf') format("opentype");;
  font-weight: 900;
  font-style: normal;
}

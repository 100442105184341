@import './css/custom.css';
@import './css/responsive.css';
@import './css/font.css';


.error {
    color: red;
}
body{
  font-family: 'sofiapro';
}
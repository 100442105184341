/*Global Css*/
body {
    background-color: #000000 !important;
    font-family: 'sofiapro';
}

*, ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

.authModal.cardModal.black-back.modal {
    background: #010101eb;
}

a {
    text-decoration: none !important;
}

.mobileheader {
    display: none !important;
}

.bannersection-mobile {
    display: none;
}

.hide-for-desktop {
    display: none;
}

.hide-for-mobile {
    display: block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {

    background-color: transparent;
    -webkit-box-shadow: 0 0 0px 0px linear-gradient(180deg, #1d1d1d08 0%, #4444440d 100%) inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #ffffff;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-control:focus {
    color: #ffffff;
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.form-control:focus-within {
    background-color: transparent;
}

.form-control:focus-visible {
    background-color: transparent;
}

.form-control:visited {
    background-color: transparent;
}

.term-condition-check {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #FFFFFF;
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.term-condition-check input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.term-condition-check input[type="checkbox"]:checked+label::after {
    content: '';
    position: absolute;
    width: 1.2ex;
    height: 0.4ex;
    background: red;
    top: 0.9ex;
    left: 0.4ex;
    border: 3px solid blue;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

input[type=checkbox]+label {
    color: #ccc;
    font-style: italic;
}

input[type=checkbox]:checked+label {
    color: #f00;
    font-style: normal;
}

/* Simple Input */
.form-control {
    font-style: normal;
    font-weight: 300;
    font-family: 'sofiapro';
    font-size: 16px;
    padding: 0 .75rem;
}

.simple-input .form-control {
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    background: transparent;
    height: 50px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 50px;
    color: #ffffff;
    padding: 0 .75rem;
}

.simple-input .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ffffff;
}

.simple-input .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #ffffff;
}

.simple-input .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #ffffff;
}

.simple-input .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #ffffff;
}

.simple-input .form-control.error {
    border: 1px solid #FF4C4C;
}

.simple-input .react-datepicker-wrapper {
    width: 100%;
}

.simple-input .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    left: 120px !important;
}

.label-input label {
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 112.1%;
    color: #FFFFFF;
    margin-bottom: 0;
}

.label-input .form-control {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    height: 50px;
    padding: 0 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #ffffff;
}
.label-input .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ffffff;
    
}

.label-input .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #ffffff;
}

.label-input .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #ffffff;
}

.label-input .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #ffffff;
}
.inputwith-icon {
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 6px;
}

.inputwith-icon .form-control {
    background: transparent;
    border: none !important;
    height: 46px;
}

.inputwith-icon.error {
    border: 1px solid #FF4C4C;
}

.inputwith-icon .form-control:focus {
    outline: none !important;
    box-shadow: none;
}

/* Select Input */
.custom-select-drop {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.custom-select-drop span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: rgba(255, 255, 255, 0.5);
}

.custom-select-drop img {
    cursor: pointer;
}

.select-drophide {
    display: none;
    transition: .4s ease-in-out;
}

.select-dropshow {
    transition: .4s ease-in-out;
    display: block;
}

.form-drop-style {
    width: 392px;
    position: absolute;
    background: #181818;
    border-radius: 0px 0px 6px 6px;
    z-index: 999;
    top: 55px;
    max-height: 320px;
    overflow-y: auto;

}

.form-drop-style ul {
    margin: 0;
    padding: 0;
}

.form-drop-style ul li {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    padding: 15px 20px;
    text-transform: capitalize;
    border-top: 0.5px solid rgba(255, 255, 255, 0.21);
}

.form-drop-style ul li:hover {
    color: #ffffff;
    background: #292929;
}

.form-drop-style ul li.border-none {
    border-top: none;
}

.cursr {
    cursor: pointer;
}

.css-1uccc91-singleValue {
    color: #ffffff !important;
}


/* Checkbox */

.radio-checkbox {
    width: 100%;
    align-items: center;
    display: flex;
    padding-left: 15px;
    padding-bottom: 15px;

}

.radio-checkbox img {
    cursor: pointer;
}

.radio-checkbox span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #FFFFFF;
    padding-left: 10px;
}

a.term-color {
    padding-left: 4px;
    color: #FF4C4C;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
}

/* Phone Number */

.phone-number .react-tel-input .form-control {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    background: transparent;
    font-style: normal;
    font-weight: 300;
    font-family: 'sofiapro';
    font-size: 16px;
    padding-left: 55px;
}

.phone-number .react-tel-input .flag-dropdown {
    background-color: transparent;
    border-right: 1px solid rgba(255, 255, 255, 0.5) !important;
    border: none;
}

.phone-number .react-tel-input .selected-flag {
    width: 45px;
    padding: 0 10px;
}

/* Defalut Button */
.select-checkboxhide {
    display: none;
}

.defalut-button {
    background: #FF4C4C !important;
    width: 202px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 50px;
    letter-spacing: 0.06em;
    color: #FFFFFF !important;
    border: none !important;
    border-color: transparent !important;
    margin: 0 auto;
    border-radius: 0;
    box-shadow: none !important;
}

.defalut-button:focus {
    border-color: transparent !important;
}

.defalut-loading-button {
    background: #FB8686 !important;
    width: 202px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 50px;
    letter-spacing: 0.06em;
    color: #FFFFFF !important;
    border: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
    margin: 0 auto;
    border-radius: 0;
}

.defalut-loading-button:focus {
    border-color: transparent !important;
}

.defalut-loading-button img {
    width: 22px;
    margin-right: 10px;
}

.defalut-button-with-icon {
    width: 202px;
    height: 50px;
    background: #FF4C4C;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin-top: 40px;
}

.defalut-button-with-icon img {
    width: 28px;
    margin-left: 10px;
}

.defalut-button-with-icon:hover {
    color: #ffffff;
}

.defalut-buton-text {
    width: 100%;
    margin: 15px auto 0px !important;
    background: transparent !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 112.1% !important;
    color: #FF4C4C !important;
    border: none;
}

.quiz-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.quiz-buttons span {
    background: rgba(255, 255, 255, 0.3);
    height: 50px;
    margin-bottom: 0 !important;
    width: 202px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px !important;
    line-height: 24px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
}

/* Default Heading */

.defalut-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.defalut-heading h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.defalut-heading span {
    width: 160px;
    height: 4px;
    background: #FF4D4D;
    border-radius: 5px;
}

.simple-heading.width-auto {
    width: auto;
}


.simple-heading {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.simple-heading h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.simple-heading span {
    min-width: 80px;
    height: 1px;
    background: #FF4D4D;
    border-radius: 5px;
    display: block;
}

/* Header Start */
header.Header-main {
    padding: 4px 0;
}

.main-header-box {
    width: 100%;
}

.main-header-box .darklight-mode {
    width: 33.3%;
}

.main-header-box .header-logo {
    width: 33.3%;
    text-align: center;
}

ul.navigation-navbar {
    display: flex;
    padding: 0;
    width: 33.3%;
    justify-content: flex-end;
    margin: 0;
    align-items: center;
}

ul.navigation-navbar li {
    padding-left: 50px;
}

ul.navigation-navbar li a {
    font-family: 'sofiapro';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
}

ul.navigation-navbar li a:hover {
    border-bottom: 2px solid #FF4D4D;
}

.header-links-dropdown-content::-webkit-scrollbar {
    width: 4px;

}

.header-links-dropdown-content::-webkit-scrollbar-thumb {
    background-color: #9D9D9D;
    outline: 1px solid #9D9D9D;
    border-radius: 70px;
}

.header-links-dropdown-content {
    opacity: 1;
    transition: .4s ease-in-out;
    overflow-y: auto;
    position: absolute;
    width: 300px;
    max-height: 380px;
    z-index: 9;
    background: #181818;
    border-radius: 0px 0px 6px 6px;
    right: 84px;
}

.header-links-dropdown-content ul {
    margin: 0;
    padding: 0;
}

.header-links-dropdown-content ul li {
    margin: 0;
    padding: 0;
}

.header-links-dropdown-content ul .border-none a {
    border-top: none;
}

.header-links-dropdown-content ul li a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    padding: 15px 20px;
    text-transform: capitalize;
    border-top: 0.5px solid rgba(255, 255, 255, 0.21);
    display: flex;
    align-items: center;
}

.header-links-dropdown-content ul li a:hover {
    color: #ffffff;
    background: #292929;
    border-bottom: none;
}

.user-drop {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.userdrop-hide {
    display: none;
}

.user-image {
    border: 2px solid #FFFFFF;
    border-radius: 100px;
    padding: 4px;
}

.user-name {
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.user-name span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 112.1%;
    color: rgba(255, 255, 255, 0.5);
}

.names {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.names span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 112.1%;
    color: #FFFFFF;
    padding-right: 5px;
}

.user-drop-box {
    width: 306px;
    position: absolute;
    background: #181818;
    border-radius: 0px 0px 6px 6px;
    right: 0;
    right: 0;
    z-index: 9;
    margin-top: 12px;
}

.user-drop-box ul {
    margin: 0;
    padding: 0;
}

.user-drop-box ul li {
    margin: 0;
    padding: 0;
}

.user-drop-box ul .border-none a {
    border-top: none;
}

.user-drop-box ul li a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    padding: 15px 20px;
    text-transform: capitalize;
    border-top: 0.5px solid rgba(255, 255, 255, 0.21);
    display: flex;
    align-items: center;
}

.user-drop-box ul li a:hover {
    color: #ffffff;
    background: #292929;
    border-bottom: none;
}

/* Toggle Button Dark Light */

.toggleButton {
    background-color: #0D0D0D;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 36px;
    width: 70px;
    height: 40px;
    border: 2px solid rgba(255, 255, 255, 0.5);
}

.toggleButton .theme {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    cursor: pointer;
}

.toggleButton .light-theme {
    background-color: transparent;
}

.toggleButton .change.dark-theme {
    background-color: #C7403B;
}

.toggleButton.change .dark-theme {
    background-color: transparent;
}

.toggleButton .change.light-theme {
    background-color: #C7403B;
}

/* Footer Start */

.footer {
    float: left;
    width: 100%;
    background-color: #0A0B0E !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #dddddd !important;
    font-size: 14px;
    padding: 60px 0;
    border-top: 0.5px solid #ffffff40;
}

.footer ul li {
    line-height: 35px;
}

.footer ul li a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.5);
}

.footer ul li a:hover {
    color: #ffffff;
}

.footer .col-head {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-bottom: 22px;
    display: block;
}

.footer ul li a img {
    margin-right: 10px;
}

.subscribe-box {
    margin-top: 20px;
}

.subscribe-form {
    width: 100%;
}

.subscribe-form div {
    display: flex;
    align-items: center;
}

.subscribe-form input {
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    padding: 0 10px;
    border-right: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 112.1%;
    color: rgba(255, 255, 255, 0.5);
    background: transparent;
}

.subscribe-form button {
    width: 105px;
    height: 50px;
    background: #FF4C4C;
    border: 1px solid #FF4D4D;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: none;
    border-radius: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
}

/* Modals */
.Login-modals.modal .modal-dialog {
    max-width: 600px;
}

.Login-modals.modal .modal-content {
    background: linear-gradient(180deg, #1D1D1D 0%, #444444 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 15px 15px;
}

.Login-modals.modal .modal-content form {
    width: 80%;
    margin: 0 auto;
}

.Login-modals.modal .modal-content h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 112.1%;
    color: #FFFFFF;
    text-align: center;
    flex: 1;
    margin-bottom: 0px;
    padding-left: 25px;
}

.Login-modals.modal .modal-content .form-group {
    margin-bottom: 1.5rem;
}

.Login-modals.modal span.forgotPassword {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: #FFFFFF;
    cursor: pointer;
}

.Login-modals.modal a.forgotPassword {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: #FFFFFF;
    cursor: pointer;
}

.for-term-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: -10px;
}

.Login-modals.modal .defaultButton {
    background: #FF4C4C;
    width: 202px;
    display: flex;
    margin: 20px auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.donthave-acc span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: #FFFFFF;
}

.donthave-acc span.signin-color {
    color: #FF4C4C !important;
    padding-left: 5px;
    font-weight: 500;
}

span.validation-error {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 112.1%;
    color: #FF4C4C;
}

.modal-head {
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    margin-bottom: 25px;
}

.modal-head .close-icon {
    cursor: pointer;
}

.register-modals.modal {
    padding: 0 !important;
}

.register-modals.modal .modal-dialog {
    max-width: 100%;
    min-height: 100%;
    margin: 0;
}

.register-box {
    width: 100%;
    display: flex;
    align-items: center;

}

.register-left {
    float: left;
    width: 29%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    height: 100vh;
    background: #FF4C4C;
}

.register-left h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 74px;
    line-height: 74px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
}

.register-left span {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

.register-right {
    background: #0A0B0E !important;
    width: 71%;
    min-height: 100vh;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 70px;
    flex-direction: column;
}

.register-right .label-input label.form-label {
    display: none;
}

.signheading-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.registration-arrow {
    display: flex;
    align-items: center;
}

.registration-arrow span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 112.1%;
    color: #FFFFFF;
    padding-left: 18px;
}

.alreadysign-logintext {
    display: flex;
    align-items: center;
}

.alreadysign-logintext span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: #FFFFFF;
}

.alreadysign-logintext p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 112.1%;
    color: #FF4C4C;
    padding-left: 10px;
    cursor: pointer;
}

.register-right form {
    width: 100%;
    padding: 25px 0;
}

.register-right form .col-md-6 {
    float: left;
    margin-bottom: 1rem;
}

.buttons-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.button-col {
    margin-right: 20px;
}

.button-col.payment {
    margin-right: 0;
    margin-top: 20px;
}

.note-text {
    width: 100%;
    text-align: center;
}

.note-text span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FF4D4D;
}

/* email verification modal */

.emailverification-modal.modal .modal-content {
    background: linear-gradient(180deg, #1D1D1D 0%, #444444 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    padding: 20px 15px;
}

.for-email-verify {
    justify-content: flex-end;
    margin-bottom: 10px;
}

.email-verification {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.email-verification h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 112.1%;
    color: #FFFFFF;
    margin: 30px 0 20px 0;
}

.email-verification span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.email-verification span a {
    color: #FF4C4C;
}

span.redcolor-login {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 112.1%;
    color: #FF4C4C;
    text-align: right;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

/* Card Modal Start */
.pay-modals.modal .modal-dialog {
    max-width: 600px;
}

.pay-modals.modal .modal-content {
    background: linear-gradient(180deg, #1D1D1D 0%, #444444 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0px;
}

.pay-modals.modal .modal-content form {
    width: 80%;
    margin: 20px auto 15px auto;
}

.pay-modals.modal .card-modal-Header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
}

.pay-modals.modal form .col-md-6 {
    float: left;
}

.pay-card {
    display: flex;
    align-items: center;
}

.pay-card span {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    padding: 5px;
}

.pay-modals.modal .pay-card span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    padding: 5px;
}

.pay-card p {
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.pay-modals.modal .modal-content .form-group {
    margin-bottom: 2rem;
}

.pay-modals.modal .defaultButton {
    background: #FF4C4C;
    width: 202px;
    display: flex;
    margin: 20px auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

/* Home Start */

.bannerSection {
    width: 100%;
    float: left;
    padding: 30px 0;
}

.banner-video {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%);
    height: 100%;
    position: relative;
}

.bannerSection-boxes {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left-video {
    width: 33.33%;
}

.left-video .courses-slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-video .courses-slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-video .courses-slider img {
    width: auto !important;
}

.right-video .courses-slider img {
    width: auto !important;
}

.Middle-content {
    width: 33.33%;
}

.Middle-content h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 112.1%;
    text-align: center;
    color: #FFFFFF;
}

.courses-slider {
    cursor: pointer;
}

.right-video {
    width: 33.33%;
}

.video-overlay {
    width: 280px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    background-size: cover;
    flex-direction: column;
    flex: 1;
}

.video-overlay img {
    flex: 1;
    width: 71px;
    padding-top: 40px;
    cursor: pointer;
}

.Middle-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Middle-content a {
    width: 202px;
    height: 50px;
    background: #FF4C4C;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin-top: 40px;
}

.Middle-content a img {
    width: 28px;
    margin-left: 10px;
}

.content-overlay {
    flex: 0;
    padding: 0 15px 20px 15px;
}

.content-overlay span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 136.7%;
    color: #BDBDBD;
    margin-bottom: 10px;
    display: block;
}

.content-overlay p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130.7%;
    letter-spacing: 0.01em;
    color: #BDBDBD;
    margin-bottom: 0;
}

/* Horizontal Tabs */

.Horizontal-tab-section {
    width: 100%;
    float: left;
    padding: 30px 0;
}

.Home-Video-tabs ul.nav.nav-tabs {
    padding: 0 65px 60px 65px;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home-Video-tabs ul.nav.nav-tabs li {
    width: 9.09%;
}

.Home-Video-tabs ul.nav.nav-tabs .nav-item a {
    background: transparent;
    border: none;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 136.7%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    padding: 0 60px 0 0;
}

.Home-Video-tabs ul.nav.nav-tabs .nav-item a.nav-link.active {
    color: #ffffff;
    font-size: 27px;
}

.Home-Video-tabs .tab-content .video-thumbnail {
    width: 100%;
    position: relative;
}

.Home-Video-tabs .tab-content .video-thumbnail img {
    width: 100%;
}

.play-icon {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.play-icon img {
    width: 100px !important;
}

/* Category List Start */

.home-cat-list {
    width: 100%;
}

.home-cat-list ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0px;
}

.home-cat-list ul li {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6%;
}

.home-cat-list ul li a {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 136.7%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    transition: transform .2s;
}

.home-cat-list ul li a:hover {
    color: #ffffff;
    transform: scale(1.1);
    /* (150% zoom)*/
}

.full-video-section {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.Only-videos {
    width: 100%;
    position: relative;
}

.Only-videos img {
    width: 100%;
    cursor: pointer;
}

/* Vertical Tabs */

.vertical-tab-section {
    width: 100%;
    float: left;
    padding: 30px 0;
}

.category-verticaltabs .tabs {
    width: 100%;
    display: flex;
}

.all-cat-text {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.category-verticaltabs .left-categories ul {
    width: 30%;
    border: none;
    margin-top: 0px;
    border-right: 1px solid #7E7E7E;
    float: left;
}

.category-verticaltabs .left-categories ul li {
    width: 100%;
    padding-bottom: 10px;
}

.category-verticaltabs .left-categories ul li a {
    background: transparent;
    border: none;
    padding: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.category-verticaltabs .left-categories ul li a.active {
    color: #FF4D4D;
    font-weight: 600;
}

.category-verticaltabs .left-categories ul li a:hover {
    color: #FF4D4D;
    font-weight: 600;
}

.category-verticaltabs .right-categories-list {
    width: 70%;
    padding: 0 50px;
    float: left;
}

.right-categories-list ul {
    width: 100%;
    display: inline;
}

.right-categories-list ul li {
    display: inline;
    padding: 12px 15px 12px 0px;
}

.right-categories-list ul li a {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 176.7%;
    letter-spacing: 0.1em;
    color: rgba(255, 255, 255, 0.5);
}

.right-categories-list ul li a.active {
    color: #FF4D4D;
}

/* Our Tutor */

.our-tutor-section {
    width: 100%;
    float: left;
    padding: 30px 0;
}

.our-tutors-box {
    width: 100%;
    float: left;
    padding: 50px 0px 0 0;
}

.tutor-list {
    width: 100%;
    float: left;
}

.tutor-list ul {
    width: 100%;
    display: inline;
    float: left;
}

.tutor-list ul li {
    display: inline-block;
    width: 205px;
    flex-direction: row;
    margin: 0 10px 20px 10px;
}

.tutor-list ul li img {
    width: 205px;
}

.tutor-photo {
    height: 100%;
    position: relative;
    text-align: center;
}

.tutor-list .tutor-info {
    position: absolute;
    bottom: 0px;
    width: 205px;
    padding: 70px 15px 10px 15px;
    background: linear-gradient(179.78deg, rgba(0, 0, 0, 0) 28.44%, rgba(0, 0, 0, 0.8) 77.35%, rgba(0, 0, 0, 0.9) 99.81%);
    text-align: left;
}

.tutor-list .tutor-info span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 136.7%;
    color: rgba(255, 255, 255, 0.7);
}

.tutor-list .tutor-info p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 136.7%;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.tutor-photo:hover .showAfterHover {
    height: 40px;
    visibility: visible;
}

.tutor-list .showAfterHover {
    height: 0;
    transition: height .4s;
    overflow: hidden;
    visibility: hidden;
}

.tutor-list .showAfterHover span {
    width: 101px;
    height: 28px;
    font-size: 14px;
    line-height: 27px;
    background: #FF4C4C;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
}

/* Popular Courses */

.popular-courses-section {
    width: 100%;
    float: left;
    padding: 30px 0;
}

/* Ready To go */

.readytogo-section {
    width: 100%;
    float: left;
    padding: 30px 0;
}

.readygo-box {
    width: 100%;
    display: flex;
    align-items: center;
}

.go-col1 {
    width: 50%;
    padding-right: 90px;
}

.go-col1 h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-bottom: 30px;

}

.go-col1 span {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 160.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.5);
}

.go-col2 {
    width: 147px;
    height: 2px;
    margin: 0px 60px;
    background: rgba(255, 255, 255, 0.2);
}

.go-col3 {
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.go-col3 img {
    width: 100%;
}

/* Our Stats Start */
.our-stats-section {
    width: 100%;
    float: left;
    padding: 30px 0;
}

.ourstats-components {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px 20px 0px;
}

.stats-box {
    width: 100%;
    text-align: center;
}

.stats-box h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 0;
}

.stats-box h4 span {
    color: #FF4D4D;
}

.stats-box p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0;
}

/* Home Testimonials start */
.all-cat-mobile {
    display: none;
}

.home-testimonial-section {
    width: 100%;
    float: left;
    padding: 70px 0;
}

.mobile-testimonial-section {
    width: 100%;
    float: left;
    padding: 70px 0;
    display: none;
}

.testimonial-boxes {
    padding: 40px 0;
}

.slider-testi {
    background: rgba(255, 255, 255, 0.05);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    padding: 20px;
}

.slider-testi .designation img {
    width: 60px !important;
    height: 60px;
}

.slider-testi .designation span {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.slider-testi .designation p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
}

.clientdescritpion {
    padding: 20px 0 0 0;
}

.clientdescritpion p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
    height: 75px;
}

.clientdetail {
    width: 100%;
}

.clientdetail img {
    width: 40px !important;
    height: 40px;
    margin-right: 1rem;
}

.clientdetail span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.testimonial-boxes .owl-theme .owl-nav.disabled+.owl-dots {
    width: 60%;
    margin: 40px auto 0 auto;
}

.testimonial-boxes .owl-theme .owl-dots .owl-dot span {
    width: 200px;
    height: 5px;
    margin: 0;
    background: rgba(255, 255, 255, 0.2);
}

.testimonial-boxes .owl-theme .owl-dots .owl-dot.active span {
    background: #FF4C4C;
}

/* Course Slider Start */

.courses-slider {
    position: relative;
}

.courseslider-boxes {
    padding-top: 40px;
}

.courses-slider img {
    width: 100% !important;
    height: 270px;
}

.courses-slider.new-heights img {
    width: 100% !important;
    height: 251px;
}

.courses-slider.new-heights1 img {
    width: 100% !important;
    height: 251px !important;
}

.courses-slider.new-heights1 .course-cat-name span {
    height: 50px;
}

.course-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.6) 20.91%, rgba(0, 0, 0, 0) 50.31%);
}

.showAfterhover {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    height: auto;
    visibility: visible;
    transition: height .4s;
    overflow: hidden;
    padding: 10px;
}

.showAfterhover-new {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    height: 100%;
    visibility: visible;
    transition: height .4s;
    overflow: hidden;
    padding: 10px;
    justify-content: flex-end;
    flex-direction: column;
}

.courses-slider.new.career img {
    width: 100% !important;
}

.course-overlay img {
    width: 41px !important;
    height: 41px !important;
    cursor: pointer;
}

.slider-desc {
    padding-left: 10px;
}

.slider-desc h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 136.7%;
    color: #FFFFFF;
    margin-bottom: 0;
}

.slider-desc span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 136.7%;
    color: #FF4D4D;
}

.course-cat-name {
    background: #FFFFFF;
    box-shadow: 0px -3px 10px rgb(0 0 0 / 25%);
    border-radius: 0px 0px 8px 8px;
    padding: 20px 20px;
}

.course-cat-name span {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 136.7%;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 3px;
    display: block;
}

.course-cat-name p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 0;
}

.course-cat-name a {
    width: 161px;
    height: 38px;
    background: #FF4C4C;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-top: 5px;
}

/* .courses-slider.new img{
    width:auto !important;
} */
.courseslider-boxes .owl-dots {
    margin-top: 40px !important;
}

.courseslider-boxes .owl-dots button.owl-dot.active span {
    width: 24px;
    border-radius: 30px;
    background: #ffffff;
}

.courseslider-boxes .owl-dots button.owl-dot span {
    background: rgba(255, 255, 255, 0.5);
}

.courseList-Section {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.course-list {
    width: 81%;
    margin: 0 auto;
}

.course-list .col-md-4 {
    float: left;
    margin-bottom: 30px;
}

.course-list .courses-slider img {
    width: 100%;
    height: 270px;
}

.courses-cat-sub {
    width: 100%;
    float: left;
}

.courses-cat-sub ul.nav.nav-tabs {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 40px;
}

.courses-cat-sub ul.nav.nav-tabs li.nav-item a.nav-link {
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 136.7%;
    color: rgba(255, 255, 255, 0.5);
}

.courses-cat-sub ul.nav.nav-tabs li.nav-item a.nav-link.active {
    color: #ffffff;
}

.courses-cat-sub .tab-content {
    float: left;
    width: 100%;
    padding-top: 50px;
}

.dots-style {
    margin-top: 40px;
}

.dots-style .owl-dots {
    margin-top: 40px !important;
}

.dots-style .owl-dots button.owl-dot.active span {
    width: 24px;
    border-radius: 30px;
    background: #ffffff;
}

.dots-style.owl-dots button.owl-dot span {
    background: rgba(255, 255, 255, 0.5);
}

/* Subscription Start */
.subscription-section {
    Width: 100%;
    float: left;
    padding: 40px 0;
}

.bredcums-subs {
    width: 100%;
    display: flex;
    align-items: center;
}

.bredcums-subs span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 112.1%;
    color: #FFFFFF;
}

.subscription-package {
    width: 100%;
    padding: 40px 0;
}

.package-boxes {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 80px 0;
}

.package-boxes .package-col {
    width: 25%;
    padding: 0px 15px;
}

.package-boxes .package-col .package-head {
    border-radius: 6px 6px 0px 0px;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    text-align: center;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.basic {
    background: #227869;
}

.cpd {
    background: #0099AE;
}

.silver {
    background: #BDBDBD;
}

.gold {
    background: #EDBA05;
}

.package-body {
    background: #000000;
    border: 0.5px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    border-radius: 0px 0px 6px 6px;
    padding: 15px 10px 20px 10px;
    border-top: none;
}

.feature-head {
    width: 100%;
    text-align: center;
    padding: 0px 10px 10px 10px;
}

.feature-head span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.5);
}

.feature-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 480px;
}

.feature-list ul {
    padding: 0px 15px;
    flex: 1;
}

.feature-list li {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
    padding: 15px 0 20px 0;
}

.feature-list span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-left: 10px;
}

.border-none {
    border-bottom: none !important;
}

.package-price {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.package-price span {
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 136.7%;
    color: #FFFFFF;
    margin-left: 1px;
}

.package-price a {
    width: 200px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-top: 20px;
}

.price-detail {
    display: flex;
    align-items: center;
}

.price-detail img {
    margin-right: 10px;
}

.for-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subscribe-toolhide {
    display: none;
}

.tooltip-icon {
    position: relative;
}

.tooltip-icon img {
    cursor: pointer;
}

.subscribe-toolshow {
    width: 240px;
    position: absolute;
    display: flex;
    background: #FF4C4C;
    border-radius: 5px;
    right: 0;
    padding: 10px;
    margin-top: 10px;
    z-index: 9;
}

.subscribe-toolshow.for-register {
    margin-top: -30px;
    background: #FFFFFF;
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 438px;
    right: 10px;
}

.subscribe-toolshow.for-register p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    margin-bottom: 0;
}

.subscribe-toolshow p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 136.7%;
    color: #FFFFFF;
    margin-bottom: 0;
}

.subscribe-toolshow p span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    color: #FFFFFF;
    background: #000000;
    padding: 5px;
    margin-left: 0;
}

/* Profile Screen Start */

.Profile-section {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.profile-bredcum {
    align-items: center;
}

.user-profiles {
    padding-top: 60px;
}

.user-profiles .tabs ul.nav.nav-tabs {
    width: 30%;
    border: none;
    margin-top: 0px;
    display: block;
}

.user-profiles .tabs ul.nav.nav-tabs li {
    width: 100%;
    padding-bottom: 0px;
}

.user-profiles .tabs ul.nav.nav-tabs li a {
    background: #ffffff;
    border: none;
    padding: 15px 20px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    ;
    letter-spacing: 0.06em;
    color: rgba(18, 18, 18, 0.5);
    border-radius: 0px;
}

.user-profiles .tabs ul.nav.nav-tabs li a.nav-link.active {
    color: #FF4D4D;
    background: #000000;
    border: 1px solid rgba(255, 255, 255, 0.6);
}

.user-profiles .tabs {
    width: 100%;
    display: flex;
}

.user-profiles .tab-content {
    width: 70%;
    padding: 25px 50px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-bottom: none;
    border-right: none;
}

.profile-details {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}

.profile-details .col-md-6 {
    float: left;
}

.update-profile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.update-profile .defalut-button {
    margin: 0;
}

.update-profile .defalut-loading-button {
    margin: 0;
}

.pass-update .defalut-button {
    margin: 0;
}

.fileupload {
    Width: 100%;
    display: flex;
    align-items: center;
    margin-top: 27px;
    margin-bottom: 37px;
}

.image-box {
    border: 1px solid #777777;
    border-radius: 5px;
    width: 150px;
    height: 131px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-box img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.image-box span {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 112.1%;
    text-align: center;
    color: #FFFFFF;
}

.upload-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 40px;
}

.upload-buttons .upload-buton {
    width: 92px;
    height: 37px;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    background: #FF4C4C;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.upload-buttons .cancel-buton {
    width: 92px;
    height: 37px;
    border: 1px solid #777777;
    border-radius: 5px;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.06em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.membership-box {
    width: 100%;
    display: flex;
    align-items: center;
}

.membership-box .membership-type {
    display: flex;
    flex-direction: column;
    padding-right: 60px;
}

.membership-box .membership-type .membership-detail {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
}

.membership-box .membership-type .membership-detail span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 112.1%;
    color: #979797;
}

.membership-box .membership-type .membership-detail p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-bottom: 0;
    margin-top: 6px;
}

.payment-table {
    width: 100%;
}

.responsive-payment-table .table.responsive {
    border: none;
}

.responsive-payment-table .table.responsive thead th {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    border: none;
    min-width: 125px;
}

.responsive-payment-table table {
    border-collapse: separate;
}

.responsive-payment-table .table.responsive thead tr {
    width: 100%;
    float: left;
    padding: 0 20px;
}

.responsive-payment-table .table.responsive tbody tr {
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    float: left;
    margin-bottom: 20px;
    padding: 0 20px;
}

.responsive-payment-table .table.responsive tbody td {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    border: none;
    min-width: 125px;
}

.responsive-payment-table .table.responsive tbody td .price-currency {
    display: flex;
    align-items: center;
}

.responsive-payment-table .table.responsive tbody td .price-currency p {
    margin-bottom: 0;
    margin-left: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
}

.responsive-payment-table .table.responsive tbody tr td a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 112.1%;
    text-align: right;
    text-decoration-line: underline !important;
    color: #FFFFFF;
}

.red-color {
    color: #FF4D4D !important;
}

/* Breadcrumbs Start */

.breadcrumbs-section {
    width: 100%;
    float: left;
    padding: 30px 0;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
}

.breadcums-info {
    width: 100%;
    display: flex;
    align-items: center;
}

.breadcrums-titles {
    padding-left: 15px;
}

.breadcrums-titles a {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.category-bredcrumbs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Course Detail Page */
.course-detail-page {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.course-detail-info {
    width: 100%;
    display: flex;
}

.left-section {
    width: 65%;
    padding-right: 50px;
}

.left-section h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.left-section p {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.7);
    height: 84px;
}

.video-box {
    width: 100%;
    position: relative;
}

.video-box img {
    width: 100%;
}

.video-overlay-back {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
}

.video-overlay-back img {
    width: 71px;
    height: 71px;
    cursor: pointer;
}

.detail-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.detail-actions a {
    height: 50px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
}

.detail-actions a span {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FF4D4D;
}

.detail-actions a img {
    padding-left: 10px;
}

.detail-actions a.take-quiz-disable {
    background: rgba(255, 255, 255, 0.2);
}

.detail-actions a.take-quiz-disable span {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}

.detail-actions a.take-quiz-enable {
    display: none;
}

.right-section {
    width: 35%;
}

.buy-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 132px;
}

.buttn-box {
    display: flex;
    flex-direction: column;
}

.buttn-box span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 10px;
}

.buttn-box a {
    background: rgba(255, 255, 255, 0.2);
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #FFFFFF;
    width: 194px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.watch-course-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 132px;
}

.watch-course-button button {
    width: 100%;
}

.course-list-box {
    width: 100%;
    margin-top: 17px;
    background: rgba(255, 255, 255, 0.05);
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    max-height: 480px;
    overflow-y: auto;
}

.course-list-box::-webkit-scrollbar {
    width: 4px;

}

.course-list-box::-webkit-scrollbar-thumb {
    background-color: #9D9D9D;
    outline: 1px solid #9D9D9D;
    border-radius: 70px;
}

.course-list-box ul li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 15px;
    cursor: pointer;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
    ;
}

.course-list-box ul li a.active .count-with-name span {
    color: #ffffff;
}

.course-list-box ul li a.active .count-with-name p {
    color: #ffffff;
}

.count-with-name {
    display: flex;
    align-items: center;
}

.count-with-name span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
}

.count-with-name p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
}

.bottom-section {
    width: 100%;
    float: left;
    border-top: 0.5px solid rgba(255, 255, 255, 0.2);
    margin-top: 50px;
}

.detail-bottom-section {
    width: 100%;
    float: left;
    display: flex;
}

.course-outline {
    width: 25%;
    display: flex;
    flex-direction: column;
    border-left: 0.5px solid rgba(255, 255, 255, 0.3);
    padding: 40px 0px 0 50px;
}

.course-outline.border-none {
    border: none;
    padding: 40px 0 0 0;
}

.course-outline h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FF4D4D;
}

.course-outline p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.7);
}

.outline-text {
    margin-top: 20px;
    padding-right: 20px;
}

.StripeElement {
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 12.5px;
}

.outline-text ul li {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.7);
    padding-bottom: 20px;
    list-style: circle;
}

.outline-text ul li {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.7);
    padding-bottom: 20px;
}

.download-resourses {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.download-resourses a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000000;
    opacity: 0.5;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    margin-bottom: 20px;
    height: 50px;
    width: 217px;
    padding: 0 20px;
}

.download-resourses a span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.5);
    opacity: 0.5;
}

.download-resourses-enable {
    margin-top: 20px;
    display: none;
    flex-direction: column;
}

.download-resourses-enable a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #FF4D4D;
    box-sizing: border-box;
    margin-bottom: 20px;
    height: 50px;
    width: 217px;
    padding: 0 20px;
}

.download-resourses-enable a span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FF4D4D;
}

/* Quiz Section Start */
.quiz-question-section {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.quiz-questions {
    width: 80%;
    margin: 0 auto;
}

.question-box {
    margin-top: 40px;
    background: #333338;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 55px 70px;
}

.question-text {
    display: flex;
    align-items: center;
    width: 100%;
}

.question-text span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.question-text p {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 136.7%;
    color: #FFFFFF;
    margin-bottom: 0px;
    padding-left: 15px;
}

.question-options {
    width: 85%;
    margin: 25px auto 0px auto;
}

.question-options .MuiFormControlLabel-root {
    margin-bottom: 1rem;
}

.question-options .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: 'sofiapro';
}

.question-options .MuiRadio-root {
    color: #ffffff;
}

.question-options .MuiCheckbox-root {
    color: #ffffff;
}

.question-options .MuiCheckbox-colorSecondary.Mui-checked {
    color: #ffffff;
}

.question-options .MuiRadio-colorSecondary.Mui-checked {
    color: #FF4D4D;
}

.quiz-submit {
    width: 40%;
    margin: 40px auto;
}

.quiz-submit button {
    width: 100%;
}

.feedback-questions {
    width: 80%;
    margin: 0 auto;
    background-color: #333338;
    padding: 30px 0;
    border-radius: 2px;
}

.feedback-box {
    margin: 45px 0;
}

.feedback-question-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedback-question-options {
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedback-question-text span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    padding-right: 20px;
}

.feedback-question-text p {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 0px;
}

.feedback-question-options .MuiRadio-root {
    color: #FF4C4C;
}

.feedback-question-options .MuiRadio-colorSecondary.Mui-checked {
    color: #FF4D4D;
}

.feedback-question-options .MuiFormControlLabel-root {
    margin: 15px 40px !important;
}

.feedback-question-options .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: 'sofiapro';
}

.feedback-question-options .MuiFormGroup-root {
    flex-direction: row;
}

.feedback-submit {
    margin-top: 40px;
}

.feedback-submit label {
    width: 100%;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: #FFFFFF;
    margin-bottom: 15px;
    display: block;
}

.feedback-submit textarea {
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    border: none;
    color: #828282;
    font-size: 16px;
    padding: 15px;
    font-weight: 300;
}

.feedback-submit textarea:focus {
    border: none;
}

.feedback-questions p {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0;
    text-align: center;
    margin-top: 5px;
}

.completed-box {
    width: 100%;
    text-align: center;
    padding: 40px 0;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.complete-circle {
    width: 113px;
    height: 113px;
    background: #219653;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    border-radius: 100px;
    margin-bottom: 10px;
}

.congras {
    font-style: normal;
    font-weight: 900;
    font-size: 43px;
    color: #219653;
    line-height: 136.7%;
    margin-bottom: 20px;
}

.completed-box p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.6px;
    color: #ffffff;
    line-height: 136.7%;
}

.goto-my {
    display: flex;
    align-items: center;
}

.goto-my p {
    margin-bottom: 0;
    padding-right: 5px;
}

.goto-my a {
    color: #219653;
    cursor: pointer;
    font-weight: 300;
    font-size: 20px;
}

.complete-circle-red {
    width: 113px;
    height: 113px;
    background: #C7403B;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    border-radius: 100px;
    margin-bottom: 10px;
}

.congras-red {
    font-style: normal;
    font-weight: 900;
    font-size: 43px;
    color: #C7403B;
    line-height: 136.7%;
    margin-bottom: 20px;
}

a.retake-red {
    color: #C7403B;
}

/* Certificate Start */
.certificate-items {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.certificate-boxes {
    width: 100%;
    float: left;
}

.info-header {
    width: 100%;
}

.certificate-boxes h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.certificate-tabs-new {
    width: 100%;
    float: left;
    margin-top: 20px;
}

.certificate-tabs-new .nav-tabs {
    border-bottom: none;
}

.certificate-tabs-new .tabs ul li {
    margin-right: 20px;
}

.certificate-tabs-new .tabs ul li a {
    background: rgba(255, 255, 255, 0.3);
    height: 50px;
    width: 202px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    border: none;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    cursor: pointer;
    border-radius: 0px;
}

.download-all {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.download-all a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 112.1%;
    text-align: right;
    text-decoration-line: underline !important;
    color: #FF4D4D;
    padding-right: 17px;
}

.upload-document {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.certificate-tabs-new .tabs ul li a.active {
    background: #FF4D4D;
    color: #ffffff;
}

.certificate-table {
    width: 100%;
    float: left;
    padding: 25px 0 0 0;
}

.goto-dasboard {
    width: 100%;
    float: left;
    padding: 30px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.goto-dasboard img {
    width: 18px;
    margin-left: 2px;
}

.certificate-table .responsive-payment-table .table.responsive tbody tr td a {
    padding: 0 20px;
    text-decoration-line: underline !important;
}

.responsive-payment-table .table.responsive tbody tr td a.download {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 112.1%;
    text-align: right;
    text-decoration-line: underline !important;
    color: #FF4D4D;
}

.goto-dasboard a {
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 112.1%;
    text-align: right;
    text-decoration: underline !important;
    color: #FF4D4D;
}

.upload-document a {
    background: rgba(255, 255, 255, 0.2);
    height: 50px;
    width: 286px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 20px;
    cursor: pointer;
}

.for-actions button {
    margin: 0;
    padding: 0;
}

.cross-photo {
    cursor: pointer;
}

/* Category Pages Start */
.course-categories {
    width: 100%;
    float: left;
    padding: 40px 0;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
}

.category-Sections {
    width: 100%;
    display: flex;
    align-items: center;
}

.category-left-section {
    width: 40%;
    padding-right: 50px;
}

.category-left-section h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin-bottom: 25px;
}

.category-left-section p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.7);
}

.catename-watch {
    width: 100%;
    margin-top: 40px;
}

.watch-course {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.watch-course span {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.watch-course a {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #EB5757;
    cursor: pointer;
}

.category-right-section {
    width: 60%;
}

.cate-course-list {
    width: 100%;
    float: left;
    padding: 30px 0;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
}

.course-list.cat-course {
    width: 90%;
    margin-top: 40px;
}

.course-list.cat-course .col-md-3 {
    float: left;
    margin-bottom: 1.5rem;
}

.cate-popular-Courses {
    width: 100%;
    float: left;
    padding: 40px 0px;
}

.foudation-years {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.learning-resources {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.learning-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.learning-slider {
    margin-top: 20px;
}

.learning-heading a {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FF4D4D;
}

.Learning-resources-section {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.resources-list {
    width: 85%;
    padding-top: 40px;
    margin: 0 auto;
    float: left;
}

.resources-list .col-md-4 {
    float: left;
    margin-bottom: 40px;
}

.resources-list .col-md-3 {
    float: left;
    margin-bottom: 40px;
}

.courses-slider.new-heights2 img {
    width: 100% !important;
    height: 200px !important;
}

.showAfterhover .coursevideo-back img {
    width: 41px !important;
    height: 41px !important;
}

.journal-club {
    width: 100%;
    float: left;
    padding: 50px 0;
}

.paper-review-section {
    width: 100%;
    float: left;
    padding: 60px 0;
}

.paper-filter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.paper-filter h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.boxes-col {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 30px;
}

.boxes-col .video-box {
    width: 30%;
}

.right-review {
    width: 70%;
    float: left;
    padding-left: 60px;
}

.right-review h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 118%;
    color: #FFFFFF;
}

.author-box {
    display: flex;
    margin-top: 10px;
}

.author-box span {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 150.7%;
    letter-spacing: 0.06em;
    color: #ffffff;
}

.author-box p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 160.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
}

.author-box p strong {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 160.7%;
    letter-spacing: 0.06em;
    color: #ffffff;
    padding-right: 5px;
}

.title-info-mobile {
    display: none;
}

.buy-buttons-mobile {
    display: none;
}

.detail-actions.for-mobile-only {
    display: none;
}

.course-details-tabs-mobile {
    display: none;
}

.foudation-years-mobile {
    display: none;
}

.cate-for-mobile {
    display: none;
}

.talkdental-items {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.dental-boxes {
    width: 100%;
    padding-top: 30px;
    display: inline-block;
    /* height:675px; */
}

.dental-big {
    width: 60%;
    float: left;
    position: relative;
    height: 100%;
}

.dental-small {
    width: 40%;
    height: calc(50% - 0px);
    position: relative;
    float: left;
    padding-left: 10px;
}

.dental-box1.dental-small.dental-grid2 {
    padding-bottom: 10px;
}

.dental-container {
    position: relative;
    height: 100%;
    background-size: cover !important;
    background-position: 50% 50% !important;
    overflow: hidden;
}

.dental-detail {
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.boxes-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    padding: 20px 30px;
}

.boxes-overlay h4 {
    font-weight: bold;
    font-size: 35px;
    color: #ffffff;
    letter-spacing: 0.06em;
}

.boxes-overlay p {
    font-weight: 300;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: 0px;
}

.dental-news {
    width: 100%;
    float: left;
    padding-top: 30px;
}

.dental-news h3 {
    font-weight: normal;
    font-size: 30px;
    color: #ffffff;
    letter-spacing: 0.06em;
}

.dental-news-list {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 30px 0;
}

.news-image-box {
    width: 30%;
    position: relative;
    height: 309px;
    background-size: cover !important;
}

.news-meta {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%);
    height: 100%;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.news-meta span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 136.7%;
    color: #BDBDBD;
    display: block;
    margin-bottom: 5px;
}

.news-meta p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130.7%;
    letter-spacing: 0.01em;
    color: #BDBDBD;
}

.news-content-box {
    width: 70%;
    padding: 0 60px;
}

.news-content-box h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 0px;
    margin-bottom: 10px;
}

.news-content-box span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FF4D4D;
    margin-bottom: 10px;
    display: block;
}

.news-content-box p {
    font-weight: 300;
    font-size: 18px;
    line-height: 160.7%;
    letter-spacing: 0.06em;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
}

.news-content-box a {
    background: #FF4C4C;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0px;
    width: 260px;
}

.careers-items {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.career-video {
    width: 100%;
    float: left;
    margin-top: 30px;
    height: 500px;
    background-size: cover;
    background-position: center;
}

.career-video .back-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.career-border {
    width: 100%;
    background: #FF4D4D;
    border-radius: 5px;
    height: 2px;
    float: left;
    margin: 40px 0;
}

.events-items {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.events-list-items {
    width: 100%;
    padding-top: 30px;
}

.events-box {
    width: 50%;
    float: left;
    padding: 0px 20px 45px 20px;
    min-height: 465px;
}

.events-divider {
    width: 100%;
    display: flex;
    align-items: center;
}

.events-image-col {
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.events-image-col img {
    width: 100%;
}

.Events-meta {
    width: 50%;
    float: left;
    padding: 0 15px;
}

.event-meta-info {
    margin-bottom: 10px;
    margin-top: -3px;
}

.event-meta-info span {
    font-weight: 500;
    font-size: 15px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FF4D4D;
}

.event-meta-info p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 110%;
    color: #FFFFFF;
    letter-spacing: 0px;
    margin-top: 1px;
}

.slider-modal-video {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.slider-modal-video iframe {
    width: 100%;
    height: 451px;
}

.talk-detail-items {
    width: 100%;
    float: left;
    padding: 30px 0;
}

.detail-section {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 250px;
}

.titlebox-meta {
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.meta-detail {
    padding-bottom: 20px;
}

.meta-detail h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.meta-detail span {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 136.7%;
    color: #FF4D4D;
}

.detail-descrption {
    width: 100%;
    float: left;
    padding: 50px 0 30px 0;
}

.detail-descrption p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 171.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.event-links a {
    width: 110px;
    height: 35px;
    background: #FF4C4C;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
}

.tooltip-icons-new {
    margin-left: 10px;
    display: block;
    margin-top: -11px;
    cursor: pointer;
}

.helppopup.modal .modal-content {
    background: linear-gradient(180deg, #1D1D1D 0%, #444444 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    padding: 0px;
}

.helppopup.modal .modal-dialog {
    max-width: 550px;
}

.help-modal-header {
    height: 60px;
    width: 100%;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
    padding: 0 25px;
}

.help-modal-header span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.help-modal-descrption {
    width: 100%;
    padding: 50px;
}

.help-modal-descrption p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.help-link-action {
    margin: 40px 0 30px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.help-link-action span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
}

.help-link-action a {
    color: #FF4C4C;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline !important;
    text-transform: uppercase;
    margin-left: 5px;
}

.help-modal-button {
    margin: 20px 0 0px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.board-subheader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
}

.board-subheader h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.board-subheader a {
    background: #FF4C4C;
    width: 202px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dasboard-my-courses {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.dasboard-my-courses h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.dasboard-tabs-main {
    width: 100%;
    margin: 0 auto;
}

.dasboard-tabs-main .tabs ul.nav.nav-tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #ffffff;
}

.dasboard-tabs-main .tabs ul.nav.nav-tabs li a.nav-link {
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 136.7%;
    padding: 20px 0;
    margin-bottom: -3px;
    cursor: pointer;
}

.dasboard-tabs-main .tabs ul.nav.nav-tabs li a.nav-link.active {
    color: #EB5757;
    border-bottom: 3px solid #EB5757 !important;
    background-color: transparent;
    border: none;
}

.dasboard-tabs-main .tabs .nav-tabs .nav-link:focus, .dasboard-tabs-main .tabs .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.my-courses-tabs-category {
    width: 100%;
    margin-top: 40px;
}

.my-courses-tabs-category .tabs ul.nav.nav-tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;
}

.my-courses-tabs-category .tabs ul.nav.nav-tabs li a.nav-link {
    color: rgba(255, 255, 255, 0.5);
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 136.7%;
    padding: 20px 0;
    margin-bottom: 0px;
}

.my-courses-tabs-category .tabs ul.nav.nav-tabs li a.nav-link.active {
    color: #ffffff;
    border-bottom: none !important;
    background-color: transparent;
    border: none;
}

.my-courses-tabs-category .tab-content .course-list {
    width: 90%;
    margin-top: 40px;
}

.my-courses-tabs-category .tab-content .course-list .slider-desc h3 {
    font-style: normal;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 136.7%;
    color: #FFFFFF;
    margin-bottom: 0px;
}

.category-search {
    background: rgba(17, 17, 17, 0.846314);
    border: 1px solid rgba(126, 126, 126, 0.751296);
    box-sizing: border-box;
    border-radius: 5px;
    width: 260px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}

.category-search img {
    margin-right: 15px;
}

.category-search input {
    background-color: transparent;
    border: none;
    height: auto;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #BDBDBD;
}

.category-search input:focus {
    outline: none;
}

.pdp-boxes-list {
    width: 100%;
    float: left;
    padding: 50px 0;
}

.pdp-boxes-list .col-md-4 {
    float: left;
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
}

.pdp-boxes-list .col-md-4.borderright {
    border-right: none;
}

.pdp-boxes-list .col-md-4.borderbottom {
    border-bottom: none;
}

.pdp-boxes {
    padding: 40px 50px 50px 50px;
}

.pdp-boxes h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 136.7%;
    letter-spacing: 0.06e;
    color: #FFFFFF;
    margin-bottom: 30px;
    height: 50px;
}

.pdp-boxes p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 25px;
}

.pdp-boxes a {
    width: 100%;
    background: #FF4C4C;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #ffffff;
}

.personal-development-planing {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.personalinfo-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.personalinfo-box div {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.personalinfo-box div span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    color: rgba(255, 255, 255, 0.5);
    width: 150px;
}

.personalinfo-box div p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    color: #FF4D4D;
    margin-bottom: 0px;
    margin-left: 40px;
}

.cycle-descrption {
    display: flex;
    align-items: center;
}

.cycle-descrption span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: #FFFFFF;
}

.cycle-descrption a {
    color: #FF4C4C;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    margin-left: 5px;
}

.personal-button-group {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.personal-button-group a.skip {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 112.1%;
    text-decoration-line: underline !important;
    color: #FF4D4D;
}

.personal-button-group a.practice-back {
    width: 202px;
    height: 50px;
    background: rgba(255, 255, 255, 0.3);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
}

.personal-button-group a.next-plan {
    background: #FF4C4C;
    width: 202px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
}

.field-practice-list {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.practice-items {
    width: 100%;
    display: flex;
    margin-bottom: 50px;
}

.practice-meta {
    width: 35%;
    padding-right: 45px;
}

.practice-meta h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 112.1%;
    color: #FFFFFF;
}

.practice-meta p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 112.1%;
    color: #ffffff;
}

.practice-form {
    width: 65%;
}

.practice-form textarea {
    width: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    border-radius: 6px;
    height: 80px;
    padding: 16px 25px 0 25px;
    color: #828282;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
}

.choose-courses-tabs {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.choose-courses-tabs ul.nav.nav-tabs {
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.choose-courses-tabs ul.nav.nav-tabs a.nav-link {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 136.7%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}

.choose-courses-tabs ul.nav.nav-tabs a.active {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 27px;
}

.choose-courses-tabs .tabs .nav-tabs .nav-link:focus, .choose-courses-tabs .tabs .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.pdp-table {
    width: 100%;
    float: left;
    padding-top: 25px;
}

.pdp-table table {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
}

.pdp-table table thead th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    text-align: center;
    color: #FF4D4D;
    border-top: none;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.reflection-heading span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    text-align: center;
    color: #FF4D4D;
}

.reflection-heading p {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 100%;
    text-align: center;
    color: #E0E0E0;
    margin-bottom: 0px;
}

.pdp-table table tbody td {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    vertical-align: middle;
}

.pdp-table table tbody td a {
    background: #FF4C4C;
    width: 125px;
    height: 36px;
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.06em;
    color: #ffffff;
    margin: 0 auto;
}

.reflec-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    position: relative;
}

.reflec-body p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
}

.startdate-drop {
    position: absolute;
    width: 239px;
    z-index: 999;
}

.startdate-drop ul {
    border-radius: 0px 0px 6px 6px;
}

.startdate-drop ul li {
    background: #FFFFFF;
    height: 46px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #333333;
    border-bottom: 0.5px solid rgba(10, 10, 10, 0.21);
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
}

.startdate-drop ul li:hover {
    background: #333333;
    color: #ffffff;
}

.pdptable-datepicker {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.pdptable-datepicker .react-datepicker__input-container input {
    background-color: transparent;
    border: none;
    height: auto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    width: 140px;
    padding: 0;
}

.pdptable-datepicker img {
    cursor: pointer;
}

.reflection-content-box {
    width: 100%;
    float: left;
    margin-bottom: 30px;
}

.reflection-heading {
    width: 100%;
    float: left;
}

.reflection-heading h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.reflection-info {
    width: 100%;
    padding: 30px 0;
    display: flex;
}

.reflection-left {
    width: 50%;
}

.reflection-col {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.reflection-col span {
    width: 210px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    color: rgba(255, 255, 255, 0.5);
}

.reflection-col p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    color: #FFFFFF;
    margin-bottom: 0px;
}

.reflection-left-form {
    width: 50%;
    float: left;
    margin-bottom: 35px;
}

.reflection-right-form {
    width: 50%;
    float: left;
    margin-bottom: 35px;
}

.reflection-bottom-form {
    width: 50%;
    float: left;
    margin-bottom: 35px;
}

.reflection-area {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.reflection-area label {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: #FF4D4D;
    margin-bottom: 15px;
}

.reflection-area textarea {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    border-radius: 6px;
    height: 105px;
    padding: 15px 25px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: #828282;
}

.reflection-area textarea:focus {
    outline: none;
}

.gdc-head {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    margin: 0 auto;
}

.gdc-head a {
    background: #FF4C4C;
    width: 202px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.06em;
}

.gdc-year {
    display: flex;
    align-items: center;
}

.gdc-year span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    color: #FF4D4D;
    margin-right: 10px;
}

.gdc-year .sc-bdnxRM.kJfjCz {
    width: 153px;
}

.gdc-year .css-1iyne87-control {
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
}

.annual-statement-content {
    width: 90%;
    margin: 15px auto;
}

.annual-box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.annual-left {
    display: flex;
    align-items: center;
    width: 50%
}

.annual-left span {
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    color: rgba(255, 255, 255, 0.5);
    width: 170px;
}

.annual-left p {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    color: #FF4D4D;
}

.annual-right {
    display: flex;
    align-items: center;
    width: 50%;
}

.annual-right span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 136.7%;
    color: #FFFFFF;
    width: 70%;

}

.annual-gdc-download {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    float: left;
}

.annual-gdc-download a {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #ffffff;
    height: 50px;
    width: 395px;
}

.annual-gdc-download a span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.06em;
    color: #FF4D4D;
}

.gdc-note-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    float: left;
}

.gdc-note-line span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    color: #FFFFFF;
}

.gdc-note-line a {
    color: #FF4D4D;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    margin-left: 5px;
}

.table-form input {
    width: 100%;
    height: 42px;
    background: transparent;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    text-indent: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.06em;
    color: #828282;
    border-radius: 0px !important;
}

.table-form input:focus {
    outline: none;
}

.table-form-gdc input {
    width: auto;
    height: 42px;
    background: transparent;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    text-indent: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.06em;
    color: #828282;
    border-radius: 0px !important;
    text-align: center;
}

.table-form input:focus {
    outline: none;
}

.duration-box-table p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #828282;
    border: 1px solid #BDBDBD;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.externa-time-picker .react_times_button {
    background: transparent;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.externa-time-picker .preview_container {
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
}

.externa-time-picker .preview_container svg {
    display: none;
}

.externa-time-picker .time_picker_modal_container {
    width: 300px !important;
}

.add-row-buton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.add-row-buton a {
    background: #FF4C4C;
    width: 137px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.home-new-video {
    position: relative;
    padding-bottom: 48.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
}

.home-new-video iframe,
.home-new-video object,
.home-new-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
}

.previous-mobile {
    display: none;
}

.home-cat-list .Dental a {
    font-size: 33px !important;
}

.dental-new-design {
    width: 100%;
    float: left;
}

.new-news-boxes {
    width: 37%;
    float: left;
    padding: 0 10px;
}

.new-news-boxes img {
    width: 100%;
    height: 340px;
}

.new-news-boxes.height-long {
    width: 26%;
}

.new-news-boxes.height-long .dental-images {
    background-position: center !important;
    background-size: cover !important;
    height: 450px;
    background-repeat: no-repeat !important;
}

.boxes-metas {
    width: 100%;
    padding: 15px 20px;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-top: none;
}

.boxes-metas h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 0px;
    margin-bottom: 10px;
}

.boxes-metas p {
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 15px;
}

.boxes-metas span {
    background: #FF4C4C;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0px;
    width: 100px;
}

.boxes-metas h6 {
    font-weight: 500;
    font-size: 11px;
    margin-bottom: 10px;
}

.dental-images {
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-bottom: none;
}

.w-90 {
    width: 90%;
}

/* Mentorship Style */
.get-started-mentorship {
    width: 100%;
    float: left;
    padding: 40px 0;
}

.get-started-mentorship .col-md-3, .col-md-6 {
    float: left;
}

.get-started-mentorship span {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FF4D4D;
}

.get-started-mentorship p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #ffffff;
    margin: 30px 0 0 0;
}

.buton-for-mentor {
    margin-top: 30px;
}

.course-overlayfor-mentor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.course-overlayfor-mentor .showAfterhover {
    height: 100%;
    align-items: flex-end;
}

.course-overlayfor-mentor .slider-desc {
    padding-left: 0px;
}

.course-overlayfor-mentor .slider-desc h4 {
    font-size: 16px;
    font-weight: 400;
}

.course-list.cat-course .col-md-2 {
    float: left;
}

.become-a-mentor {
    width: 100%;
    background: #ffffff;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.become-a-mentor span {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #333333;
}

.become-mentor-buton {
    position: absolute;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FF4D4D;
    height: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff !important;
    width: 205px;
}

/* Mentor Dashboard Style */
.mentordash-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
}

.mentordash-buttons button {
    background: #FF4D4D;
    height: 50px;
    width: 205px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-right: 15px;
    border: none;
    box-shadow: none;
}

.mentordash-buttons a {
    background: rgba(255, 255, 255, 0.3);
    ;
    height: 50px;
    width: 205px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login-modals.add-patient.modal .modal-dialog {
    max-width: 550px;
}

.Login-modals.add-patient.modal .modal-content {
    padding: 0;
}

.patient-header {
    padding: 10px 20px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
}

.Login-modals.add-patient.modal form {
    padding: 35px 0;
}

.mentor-tool-tip {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 388px;
    padding: 0 10px;
}

.mentor-tool-tip span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.Login-modals.add-patient.modal form .term-condition-check {
    justify-content: center;
}

.patient-detail img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    margin-right: 20px;
}

.in-progress p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin: 0;
}

.status-change span {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #00FFB2;
}

.payment-status a {
    background: transparent !important;
    height: auto !important;
    width: auto !important;
    color: #FF4D4D !important;
    font-style: normal;
    font-weight: 300;
    font-size: 20px !important;
    line-height: 136.7%;
    letter-spacing: 0.06em;
}

.payment-done span {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #00FFB2;
}

.mentor-actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.mentor-edit {
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.3);
    width: 87px;
}

.mentor-edit span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    padding-top: 3px;
}

.view-conversation a {
    height: 30px !important;
    color: #ffffff !important;
    width: auto !important;
    padding: 0 20px;
}

.upcoming-calls-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
    float: left;
}

.upcoming-calls-head span {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.upcoming-calls-head a {
    background: rgba(255, 255, 255, 0.3);
    ;
    height: 50px;
    width: 205px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Conversation Style */
.conversation-view {
    width: 100%;
    float: left;
}

.conversation-view .col-md-6 {
    float: left;
}

.patient-title span {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.patients-details {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 50px;
}

.detail-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 22px;
}

.detail-col span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 136.7%;
    color: rgba(255, 255, 255, 0.5);
    width: 140px;
}

.detail-col p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 136.7%;
    color: #ffffff;
    margin: 0;
}

.patient-description {
    width: 100%;
    float: left;
    margin-bottom: 22px;
    padding: 0 25px 0 0;
}

.patient-description span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.patient-description p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.7);
    ;
    margin: 0;
}

.patient-fileupload {
    width: 100%;
    float: left;
    margin-top: 22px;
}

.patient-fileupload span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.patient-chat {
    width: 100%;
    float: left;
}

.chat-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-head span {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.chat-head button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    box-shadow: none;
    background: #FF4D4D;
    height: 50px;
    width: 202px;

}

.chat-head button span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
}

.chat-box {
    width: 100%;
    float: left;
    background: rgba(255, 255, 255, 0.1);
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 26px 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.input-tool {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input-tool input {
    height: 50px;
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.7);
    outline: none;
    background: transparent;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    text-indent: 15px;
    width: 100%;
    margin-right: 15px;
}

.input-tool button {
    border: none;
    background: #FF4C4C;
    height: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 202px;
}

.end-chat-session {
    width: 100%;
    float: left;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.end-chat-session button {
    background: #000000;
    border: 1px solid #FF4D4D;
    box-sizing: border-box;
    width: 278px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.06em;
    color: #EB5757;
}

.messages-conversation {
    width: 100%;
    float: left;
    max-height: 450px;
    overflow-y: auto;
    padding: 40px 20px 0 20px;
}

.reply-body {
    background: #FFFFFF;
    border-radius: 0px 10px 10px 10px;
    max-width: 479px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px 15px;
    margin-bottom: 20px;
}

.user-reply-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.user-reply-info h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 136.7%;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
}

.user-reply-info span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 136.7%;
    color: rgba(0, 0, 0, 0.5);
}

.reply-body p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
    margin: 0;
}

.sent-body {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-end;
    width: 100%;
}

.sent-display {
    background: #FF4D4D;
    border-radius: 0px 10px 10px 10px;
    max-width: 454px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    width: auto;
    padding: 10px 15px;
}

.user-sent-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.user-sent-info h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 136.7%;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
}

.user-sent-info span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 136.7%;
    color: rgba(255, 255, 255, 0.9);
}

.sent-body p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
    text-align: right;
    margin: 0;
}

.Login-modals.schedule.modal .modal-dialog {
    max-width: 920px;
}

.Login-modals.schedule.modal .modal-content {
    padding: 0;
}

.Login-modals.schedule.modal .modal-content .button-col.payment {
    margin-right: 0;
    margin-top: 20px;
    float: left;
    width: 100%;
}

.schedule-modal {
    width: 100%;
    float: left;
    padding: 25px 0px;
}

.date-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.date-head span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.date-head p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #FF4D4D;
    margin: 0;
}

.date-slots {
    width: 100%;
    float: left;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 8px;
}

.slot-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px 0px;
}

.slot-list .mainslot {
    width:100%;
    float:left;
}

.slot-list .mainslot .slot-col {
   float:left;
}



.slot-list .slot-col.selected {
    background: #FF4D4D;
}

.slot-list .slot-col {
    background: rgba(255, 255, 255, 0.3);
    ;
    height: 32px;
    width: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0;
    color: #FFFFFF;
    margin: 4px;
    cursor: pointer;
}

.mentorship-steps {
    width: 100%;
    float: left;
    padding: 80px 0 30px 0;
}

.steps-col {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.steps-col.second-step {
    width: 100%;
    float: left;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 0px;
}

.steps-col .col-md-4, .col-md-8 {
    float: left;
}

.step-left {
    width: auto;
    float: left;
}

.step-left span {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 136.7%;
    color: #FFFFFF;
}

.step-left h5 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 136.7%;
    color: #FF4D4D;
}

.step-left p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 167.7%;
    color: #ffffff;
    letter-spacing: 0.06em;
}

.steps-right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.steps-right.new {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.step-horizontal {
    margin-left: 15px;
    margin-top: 120px;
}

.date-calendar {
    width: 100%;
    float: left;
    margin-top: 10px;
}

.date-calendar .react-calendar {
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
}

.date-calendar .react-calendar .react-calendar__tile {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 136.7%;
    letter-spacing: 0.06em;
    color: #BDBDBD;
    font-family: 'sofiapro';
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: none;
    border-left: none;
}

.date-calendar .react-calendar .react-calendar__tile abbr[data-original-title], abbr[title] {
    text-decoration: none !important;
}

.date-calendar .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: transparent;
}

.date-calendar .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #FF4D4D !important;
    color: #ffffff;
    border: 1px solid #FF4D4D;
}

.date-calendar .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: #FF4D4D !important;
    color: #ffffff;
    border: 1px solid #FF4D4D;
}

.date-calendar .react-calendar__navigation {
    display: none;
    height: 44px;
    margin-bottom: 1em;
}

.date-calendar .react-calendar__month-view__weekdays__weekday {
    color: #FF4D4D;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-transform: capitalize;
    font-family: 'sofiapro';
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: none;
    border-left: none;
    border-top: none;
}

.date-calendar .react-calendar__tile--active:enabled {
    background: #FF4D4D;
    color: #ffffff;
    border: 1px solid #FF4D4D;
}

.filepatients {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: wrap;
    margin-top: 20px;
}

.filepatients .files-list {
    width: 180px;
    height: 121px;
    margin: 0 10px 10px 0;
}

.file-image-lighter {
    position: relative;
}

.file-image-lighter img {
    width: 180px;
    height: 121px;
    position: relative;
}

.file-overlay {
    display: none;
}

.file-image-lighter:hover .file-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.file-overlay img {
    width: 24px;
    height: 24px;
}

.player-wrapper {
    position: relative;
}

.player-wrapper:hover .file-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.media-close-icon {
    position: absolute;
    top: -23px;
    right: -18px;
    cursor: pointer;
}

.media-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.media-content img {
    width: auto;
}

.Login-modals.add-patient.modal .modal-content .checkbox-col {
    margin-top: 1.5rem;
}

.Login-modals.add-patient.edit-patient.modal .modal-content .edit-scroll {
    max-height: 80vh;
    overflow-y: auto;
}

.edit-photos {
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.edit-photos .edit-col {
    width: 120px;
    height: 80px;
    position: relative;
    margin: 0 10px 10px 0;
}

.edit-photos .edit-col img {
    width: 120px;
    height: 80px;
}

.remove-overlay {
    display: none;
}

.edit-photos .edit-col:hover .remove-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100%;
    background: #00000096;
    height: 100%;
    cursor: pointer;
}

.remove-overlay img {
    width: 24px !important;
    height: 24px !important;
}

.react-confirm-alert-body .heading {
    color: #ffffff !important;
    font-size: 20px !important;
    font-weight: 400;
}

.react-confirm-alert-body .heading.error {
    color: #D95757 !important;
}

.react-confirm-alert-body .box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}

.react-confirm-alert-body .box img {
    display: none;
}

#react-confirm-alert-firm-svg {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    left: 0;
    background: #000000;
    opacity: 0.8;
}

.react-confirm-alert-body {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

#react-confirm-alert {
    width: 400px;
    position: fixed;
    left: calc(50vw - 200px);
    top: calc(45vh - 50px);
    background: linear-gradient(180deg, #1D1D1D 0%, #444444 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: .3rem;
    padding: 20px 50px;
    color: white;
    z-index: 9999999999999;

}

.react-confirm-alert-body .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex-flow: row-reverse;
}

.react-confirm-alert {
    display: flex;
    align-items: center;
    justify-content: center;

}

.react-confirm-alert-body button.yes {
    background: #FF4C4C !important;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 50px;
    letter-spacing: 0.06em;
    color: #FFFFFF !important;
    border: none !important;
    border-color: transparent !important;
    margin: 0 0 0 15px;
    border-radius: 0;
    box-shadow: none !important;
}

.react-confirm-alert-body button.no {
    background: transparent !important;
    width: 120px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 50px;
    letter-spacing: 0.06em;
    color: #FFFFFF !important;
    border: 1px solid #ffffff !important;
    margin: 0;
    border-radius: 0;
    box-shadow: none !important;
}

.Login-modals.add-patient.slots.modal .modal-dialog {
    max-width: 920px;
}

.slots-tabs .tabs {
    width: 100%;
    padding: 20px 30px;
    float: left;
}

.slots-tabs .tabs ul {
    width: 24%;
    display: flex;
    flex-direction: column;
    border: none;
    float: left;
    padding: 0 20px 0 0;
}

.slots-tabs .tabs ul li {}

.slots-tabs .tabs ul li a.active {
    background: transparent;
    color: #FF4C4C;
    border: none;
    border-bottom: 1px solid #FF4C4C;
}

.slots-tabs .tabs ul li a {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 136.7%;
    color: #ffffff;
    border: none;
    padding-left: 0;
    padding: 10px 0 5px 0;
}

.slots-tabs .tabs ul li a:hover {
    border: none;
    cursor: pointer;
}

.slots-tabs .tabs .tab-content {
    width: 75%;
    float: left;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    padding: 20px;
}

.add-remove-form .form-group {
    margin-bottom: 15px !important;
}

.add-remove-form .form-group label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 136.7%;
    color: #ffffff;
    margin-bottom: 3px;
}

.add-remove-form .form-group select {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    background: transparent;
    height: 50px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 50px;
    color:#ffffff;
    padding: 0 0.75rem;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-position: 95% 50% !important;
    background-repeat: no-repeat !important;
    cursor: pointer;
}
.add-remove-form .form-group select option{
    color:black;
}
.add-more-button {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 20px;
}
.add-more-button  button{
    background: transparent;
    border-radius:0px;
    color:#ffffff;
    border:1px solid #ffffff;
    font-weight: 500;
    font-size: 16px;
}
.add-more-button  button:hover{
    background: #ffffff;
    color:#000000;
    border:1px solid #ffffff;
}

.slot-button {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.slot-button button {
    width: 140px;
    height: 40px;
    background: #FF4C4C;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    border: none;
    margin-top: 15px;
}

.mentor-tabs-for-tables{
    width:100%;
    float:left;
    padding-top:30px;
}
.mentor-tabs-for-tables ul.nav.nav-tabs{
    width:15%;
    float:left;
    border:none;
    padding: 0 20px 0 0;
}
.mentor-tabs-for-tables ul.nav.nav-tabs li.nav-item{
    width:100%;
    float:left;
}
.mentor-tabs-for-tables .tabs ul.nav.nav-tabs li a {
    background: transparent;
    border: none;
    padding: 15px 0px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112.1%;
    letter-spacing: 0.06em;
    color: rgba(18, 18, 18, 0.5);
    border-radius: 0px;
}
.mentor-tabs-for-tables  .tabs ul.nav.nav-tabs li a.nav-link.active {
    color: #FF4D4D;
    background: transparent;
    border-bottom: 1px solid #FF4D4D;
}
.mentor-tabs-for-tables .tab-content{
    width:85%;
    float:left;
    padding:0 20px;
}
.mentor-tabs-for-tables .tab-content .pdp-table{
    padding-top:0;
}
.slots-boxes-colum {
    width:100%;
    float:left;
}
.slots-boxes-colum .col-md-3{
    float:left;
    padding-bottom: 15px;
}
.slots-timing-box{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255,255,255,0.5);
    height: 50px;

}
.remove-for-mentor{
    height:95px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mentorButton{
    margin-right: 10px;
}


.addpatientform .simple-input .form-control.error {
    border: 1px solid rgba(255,255,255,0.5);
}

.addpatientform .inputwith-icon.error {
    border: 1px solid rgba(255,255,255,0.5);
  }

@font-face {
    font-family: 'sofiapro';
    src: url('./fonts/sofia-pro/sofiapro-light.otf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'sofiapro';
    src: url('./fonts/sofia-pro/sofiapro-regular.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'sofiapro';
    src: url('./fonts/sofia-pro/sofiapro-medium.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'sofiapro';
    src: url('./fonts/sofia-pro/sofiapro-semibold.otf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'sofiapro';
    src: url('./fonts/sofia-pro/sofiapro-bold.otf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'sofiapro';
    src: url('./fonts/sofia-pro/sofiapro-black.otf');
    font-weight: 900;
    font-style: normal;
}